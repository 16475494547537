<template>
    <div>
    <v-container >
    <CRUDCriteria
      :showSearch="false"
      :showReset="true"
    >
      <template v-slot:criteriaRows>
          <v-row>
        <v-col cols="4">
        <v-text-field
          id="accountWildCard"
          v-model="criteriaState.wildcard"
          label="Wildcard search (acct #, long desc or short desc)"
          @input="wildcardSearchDelayed"
          autocomplete="off"
          ></v-text-field>
             </v-col>
          </v-row>
        </template>
    </CRUDCriteria>
      <CRUDList
        :canAdd="true"
        :canEdit="true"
        :canSave="true"
        :baseCrudKey="baseCrudKey"
        :headers="headers"
        :mergeCriteria="false"
        toolbarTitle="Accounts"
        @loadCRUDList="handleLoadCRUDList"
        @upsertItem="upsertItem"
        :defaultSortBy="sortBy"
        @activateItem="activateWarAccount"
      >
        <template v-slot:[`editedItem`]="{}">
          <AccountsEditedItem :baseCrudKey="baseCrudKey" />
        </template>
        <template v-slot:[`additionalActions`]="{}">
        <AuditDialog
          v-if="getActiveTransID"
          :identifier="getActiveTransID"
          activatorCaption="Audit History"
        />
      </template>
      </CRUDList>
    </v-container>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import CRUDList from '../../common/base/crud/CRUDList.vue';
import CRUDCriteria from '../../common/base/crud/CRUDCriteria.vue';
import AccountsEditedItem from './AccountsEditedItem.vue';
import AuditDialog from '../../common/audit/AuditDialog.vue';

const getHeaders = () => {
  const headers = [
    { text: 'Acct #', value: 'account' },
    { text: 'Long Description', value: 'longDesc' },
    { text: 'Short Description', value: 'shortDesc' },
  ];
  return headers;
};

export default {
  name: 'AccountList',
  components: {
    CRUDList,
    CRUDCriteria,
    AccountsEditedItem,
    AuditDialog,
  },
  data: () => ({
    baseCrudKey: 'warAccount',
    delayTimerId: undefined,
    activeWarAccountItem: undefined,
    headers: [],
    sortBy: [
      {
        column: 'account',
        direction: 'asc',
      },
    ],
  }),
  created() {
    this.headers = getHeaders();
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
      'index',
    ]),
    criteriaState: {
      get() {
        return this.criteria(this.baseCrudKey);
      },
      set(value) {
        this.setCriteria([this.baseCrudKey, value]);
      },
    },
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    getActiveTransID() {
      return this.activeWarAccountItem && this.activeWarAccountItem._id;
    },
  },
  methods: {
    ...mapActions('war/account', [
      'loadAccounts',
      'upsrtAccount',
    ]),
    ...mapMutations('base/crud', [
      'setCriteria',
    ]),
    handleLoadCRUDList() {
      const { baseCrudKey } = this;
      const criteria = this.criteria(baseCrudKey);
      this.loadAccounts({
        criteria,
        baseCrudKey,
      });
    },
    wildcardSearchDelayed() {
      // cancel pending call
      clearTimeout(this.delayTimerId);

      const { baseCrudKey } = this;
      // delay new call
      this.delayTimerId = setTimeout(async () => {
        await this.loadAccounts({
          criteria: this.criteriaState, baseCrudKey,
        });
      }, 250);
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    upsertItem(item) {
      const { baseCrudKey } = this;
      if (!this.isNewItem()) {
        if (!window.confirm('This will affect to all associated Transactions, are you sure?')) {
          return false;
        }
      }
      this.upsrtAccount({ item, baseCrudKey });
      return true;
    },
    activateWarAccount(val) {
      this.activeWarAccountItem = val;
    },
  },
};
</script>
