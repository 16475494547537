<template>
  <v-container>
    <v-row>
      <v-col cols="3">
        <v-text-field
          :autofocus="true"
          label= "Account Number"
          v-model="editedItem.account"
          :rules="[lengthValidation, checkDuplicateValues, ...reqdSelectValidation]"
        ></v-text-field>
      </v-col>
      <v-col cols="3">
        <v-text-field
         label= "Long Description"
         v-model= "editedItem.longDesc"
        ></v-text-field>
      </v-col>
       <v-col cols="3">
        <v-text-field
        label= "Short Description"
        v-model= "editedItem.shortDesc"
      ></v-text-field>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  mapGetters,
  mapMutations,
  mapState,
} from 'vuex';
import { checkDuplicate, validateInputLength } from '../../../util/shared/tmc-global';

export default {
  name: 'AccountsEditedItem',
  components: {
  },
  props: {
    baseCrudKey: {
      type: String,
    },
  },
  computed: {
    ...mapGetters('base/crud', [
      'criteria',
      'item',
      'index',
    ]),
    ...mapState({
      accountList: (state) => state.base.crud.items.warAccount,
    }),
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
    changes() {
      return this.editedItem.changes || [];
    },
    reqdSelectValidation() {
      return [
        (value) => !!value || 'Required',
      ];
    },
    isNewItem() {
      return this.index(this.baseCrudKey) === -1;
    },
    checkDuplicateValues() {
      return this.checkDuplicate({
        stateArr: this.accountList,
        fieldName: 'account',
        val: this.editedItem,
        isNewItem: this.isNewItem,
      });
    },
    lengthValidation() {
      return validateInputLength({
        fieldName: 'account',
        label: 'Account number',
        val: this.editedItem,
        requiredLength: 4,
      });
    },
  },
  methods: {
    checkDuplicate,
    ...mapMutations('base/crud', [
      'setItem',
    ]),
  },
};
</script>
