<template>
  <div>
    <v-container >
      <Accountslist/>
    </v-container>
  </div>
</template>

<script>
import Accountslist from '../../../components/War/Accounts/AccountsList.vue';

export default {
  name: 'WarAccounts',
  components: {
    Accountslist,
  },
  data: () => ({
  }),
  created() {
  },
  computed: {
  },
  methods: {
  },
};

</script>
